import { MDBContainer, MDBRow } from "mdbreact";

import DataMapLocations from '../components/gm/content';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import { graphql } from "gatsby";

const FindLocations = ({ location, data }) => {
  const allNodeCenter = data.allNodeCenter.edges
  const featuredServices = data.allTaxonomyTermServices.edges
  const allPracticemaxRatings = data.allPracticemaxRatings
  return (
    <Layout>
    <SEO 
        title="Find Locations"
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow className="my-sm-2 my-md-4">
            <DataMapLocations 
              allNodeCenter={allNodeCenter}
              featuredServices={featuredServices}
              allPracticemaxRatings={allPracticemaxRatings}
              location={location} />
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  );
};

export default FindLocations

export const query = graphql`
query Findlocations {
  allNodeCenter(filter: {
    # delete 2 when remove kys
    field_center_group: {in: [0, 1, 2]}, 
    status: {eq: true}
  }) {
    edges {
      node {
        title
        field_latitude
        field_longitude
        path {
          alias
        }
        field_address_fl
        field_city
        field_state
        field_zip
        field_center_group
        relationships {
          field_services {
            name
            id
          }
          field_image_carousel {
            localFile {
              childImageSharp {
                list: gatsbyImageData(layout: FIXED width: 82 height: 44)
                window: gatsbyImageData(layout: CONSTRAINED width: 110 height: 94)
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
  allTaxonomyTermServices(filter: {field_featured: {eq: true}}, sort: {fields: weight}) {
    edges {
      node {
        id
        name
        field_featured
        field_flocations_name
      }
    }
  }
  #centerStarsRatings
  allPracticemaxRatings {
    totalCount
    edges {
      node {
        shortName
        id
        medicalOrganizationEntity {
          name
          aggregateRating {
            _type
            bestRating
            ratingCount
            ratingValue
            worstRating
          }
        }
      }
    }
  }
}
`